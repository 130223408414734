import { ACCESS_TOKEN_KEY, AVATAR_DEFAULT } from "@app/config/const";
import StorageServices from "@services/local.storage";
import {Button, Dropdown, Grid, Menu, MenuProps} from "antd";
import React, {useEffect, useMemo, useState} from "react";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "@store/index";
import {useListNavigationQuery} from "@store/api/navigation.api";

const { useBreakpoint } = Grid;
const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = !useBreakpoint().lg;
  const user = useAppSelector((state) => state.user) as User;
  const [openMenu, setOpenMenu] = useState(false);

  const [item, setItem] = useState<any[]>(null);

  const {
    data: navigationData,
    refetch: refetchAll
  } = useListNavigationQuery({
  });

  useEffect(() => {
    if(navigationData) setItem(navigationData?.map((item) => ({
      label: <Link className="text-14 font-500" to={item?.redirectUrl}>{item?.name}</Link>,
      key: item._id,
      children: item?.child?.map(
        (childs) => ({
          label: <Link className="text-11 font-500" to={childs?.redirectUrl}>{childs?.name}</Link>,
          key: childs._id,
        }

      ))
    }
    ))
    );


  }, [navigationData]);


  const dropMenu = useMemo<MenuProps["items"]>(() => {
    return [
      {
        key: "1",
        label: (
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <img
                className="w-29 h-29 rounded-50 object-cover mr-8"
                src={user?.avatar ?? AVATAR_DEFAULT}
                alt="avatar"
              />
              <p className="my-0 text-11 font-600 text-dark-blue">Học sinh</p>
            </div>

            <CaretUpOutlined className="text-dark-blue" />
          </div>
        ),
      },
      {
        key: "2",
        label: (
          <Link to="/learning-report" className="text-11 font-500">
            <span className="text-dark-blue">Theo dõi việc học của con</span>
          </Link>
        ),
      },
      {
        key: "3",
        label: (
          <Link to="/profile" className="text-11 font-500">
            <span className="text-dark-blue">Thông tin cá nhân</span>
          </Link>
        ),
      },
      {
        key: "4",
        label: (
          <div
            className="text-11 font-500"
            onClick={() => {
              localStorage.clear();
              window.location.reload();
            }}
          >
            <span className="text-dark-blue">Đăng xuất</span>
          </div>
        ),
      },
    ];
  }, [user?.avatar]);

  return (
    <div className="app-header content-layout">
      <div
        className={`flex justify-between w-full items-center h-full ${
          isMobile ? "px-16" : "px-10"
        }`}
      >
        <Link to="/">
          <img
            className="cursor-pointer"
            src="/assets/image/logo.png"
            alt="logo"
          />
        </Link>
        {!isMobile
          ? !location.pathname.includes("/auth/") && (
              <>
                <div className="flex">
                  {
                    item && <Menu theme={'light'} mode="horizontal" items={item} />
                  }
                  {/*<Menu mode="horizontal">*/}
                  {/*  <Menu.Item key="1">*/}
                  {/*    <Link to="/">Trang chủ</Link>*/}
                  {/*  </Menu.Item>*/}
                  {/*  <Menu.Item key="2">*/}
                  {/*    <Link to="/courses">Khóa học</Link>*/}
                  {/*  </Menu.Item>*/}
                  {/*  <Menu.Item key="3">*/}
                  {/*    <Link to="/live-class">Live class</Link>*/}
                  {/*  </Menu.Item>*/}
                  {/*  <Menu.Item key="4">*/}
                  {/*    <Link to="/exam-school">Trường thi</Link>*/}
                  {/*  </Menu.Item>*/}
                  {/*</Menu>*/}
                </div>
                {StorageServices.getData(ACCESS_TOKEN_KEY, null) ? (
                  <Dropdown
                    trigger={["click"]}
                    overlayClassName={"custom-header-dropdown"}
                    menu={{ items: dropMenu }}
                  >
                    <div className="flex items-center justify-between min-w-180 header-profile-button">
                      <div className="flex items-center">
                        <img
                          className="w-29 h-29 rounded-50 object-cover mr-8"
                          src={user?.avatar ?? AVATAR_DEFAULT}
                          alt="avatar"
                        />
                        <p className="my-0 text-11 font-600 text-dark-blue">
                          {user?.fullName ?? "Học sinh"}
                        </p>
                      </div>

                      <CaretDownOutlined className="text-dark-blue" />
                    </div>
                  </Dropdown>
                ) : (
                  <div className="flex items-center">
                    <Link
                      className="mr-12 text-15 font-600 text-dark-blue"
                      style={{ textDecoration: "none" }}
                      to="/auth/sign-in"
                    >
                      Đăng nhập
                    </Link>
                    {/*<Link*/}
                    {/*  className="text-15 font-600 text-dark-blue flex items-center justify-center rounded-50 h-40 w-100"*/}
                    {/*  style={{*/}
                    {/*    textDecoration: "none",*/}
                    {/*    border: "1px solid #13345F",*/}
                    {/*  }}*/}
                    {/*  to="/auth/sign-up"*/}
                    {/*>*/}
                    {/*  Đăng kí*/}
                    {/*</Link>*/}
                  </div>
                )}
              </>
            )
          : !location.pathname.includes("/auth") && (
              <div className="mobile-menu">
                <img
                  onClick={() => {
                    setOpenMenu((state) => !state);
                  }}
                  src="/assets/image/menu.svg"
                  alt="menu-icon"
                />
                {openMenu && (
                  <div className="menu-mobile-content">
                    <div className="flex justify-end">
                      <img
                        onClick={() => {
                          setOpenMenu((state) => !state);
                        }}
                        src="/assets/image/close-icon-mobile.svg"
                        alt="icon"
                      />
                    </div>
                    <div className="menu">
                      <Link
                        onClick={() => {
                          setOpenMenu(false);
                        }}
                        className={location.pathname === "/" ? "active" : ""}
                        to="/"
                      >
                        Trang chủ
                      </Link>
                      <Link
                        onClick={() => {
                          setOpenMenu(false);
                        }}
                        className={
                          location.pathname === "/courses" ? "active" : ""
                        }
                        to="/courses"
                      >
                        Khóa học
                      </Link>
                      <Link
                        onClick={() => {
                          setOpenMenu(false);
                        }}
                        className={
                          location.pathname === "/live-class" ? "active" : ""
                        }
                        to="/live-class"
                      >
                        Liveclass
                      </Link>
                      <Link
                        onClick={() => {
                          setOpenMenu(false);
                        }}
                        className={
                          location.pathname === "/exam-school" ? "active" : ""
                        }
                        to="/exam-school"
                      >
                        Trường thi
                      </Link>
                    </div>
                    <div className="flex items-center">
                      <Button
                        onClick={() => {
                          setOpenMenu(false);
                          navigate("/auth/sign-in");
                        }}
                        className="sign-in"
                      >
                        Đăng nhập
                      </Button>
                      {/*<Button*/}
                      {/*  onClick={() => {*/}
                      {/*    setOpenMenu(false);*/}
                      {/*    navigate("/auth/register");*/}
                      {/*  }}*/}
                      {/*  className="sign-up"*/}
                      {/*>*/}
                      {/*  Đăng kí*/}
                      {/*</Button>*/}
                    </div>
                  </div>
                )}
              </div>
            )}
      </div>
    </div>
  );
};

export default React.memo(Header);
